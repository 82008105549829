.justify-text {
    text-align: justify;
    text-justify: inter-word;
  }

.justify-right {
    text-align: right;
  }  

/* Ensure the section doesn't overflow on mobile */
.full-height-section {
  padding: 20px;
  overflow: hidden;
}

/* Adjust image sizing for mobile */
.smaller-image {
  max-width: 100%; /* Ensure the image doesn't exceed the screen width */
  height: auto;
  border-radius: 8px;
}

/* Add padding around the image for mobile */
.img-col {
  padding: 15px;
}

.hero-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
}

/* Cards styling */
.expertise-card {
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
}

/* Expert list inside the card */
.expertise-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.expertise-list li {
  font-size: 1.1rem;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.expertise-list li:last-child {
  border-bottom: none;
}

/* Prevent overflow on smaller screens */
@media (max-width: 768px) {
  .hero-container {
      padding-left: 10px;
      padding-right: 10px;
  }

  .img-col {
      margin-top: 20px; /* Add space above the image on mobile */
  }
}
