.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px 10px;
  background-color: #007bff; /* Or any color of your choice */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Make sure it appears above other content */
  opacity: 0.7; /* Optional: for better visibility */
  transition: opacity 0.3s; /* Optional: for smooth visibility transition */
}

.back-to-top:hover {
  opacity: 1; /* Optional: change opacity on hover */
}
