.contact-bg {
    background-image: linear-gradient(to right, #000821, rgba(0, 8, 33, 0) 50%, rgba(0, 8, 33, 0) 50%, #000821), url('../images/marsprobe.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensure the image scales to fill the container */
    background-color: #000821;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100vh; /* Make the section the full height of the viewport */
    min-height: 100vh; /* Ensure it's at least as tall as the viewport */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent content overflow */
}


.semitransparent-card {
    background-color: rgba(255, 255, 255, 0.7); /* White background with 50% opacity */
    /* Add more styling as needed */
  }
  
  