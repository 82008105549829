.navbar-background {
  /* Use a linear gradient with the background color at the edges */
  background-image: linear-gradient(to right, #000821, rgba(0, 8, 33, 0) 50%, rgba(0, 8, 33, 0) 50%, #000821), url('../images/banner3.webp');
  background-size: 512px 512px; 
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-color: #000821; /* This will be the base color */
}

.navbar-collapse {
  padding-left: 20px; /* Adjust this padding as needed */
}

/* Styling the navigation links */
.navbar-background .nav-link {
  color: white !important;
}

/* Styling the hamburger menu */
.navbar-background .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-width='3' d='M0,5 30,5' /><path stroke='rgba(255, 255, 255, 1)' stroke-width='3' d='M0,14 30,14' /><path stroke='rgba(255, 255, 255, 1)' stroke-width='3' d='M0,23 30,23'/></svg>");
}

/* Optionally, if you want to change the color on hover as well */
.navbar-background .nav-link:hover {
  color: #ccc !important; /* Light gray for hover, you can choose your color */
}
