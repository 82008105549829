.portfolio-overlap-layout {
    position: relative;
    height: 800px; /* Adjust the height based on your content */
}
  
.portfolio-item {
    width: 50%;
    position: absolute;
    transition: transform 0.3s ease, z-index 0.3s ease;
    /* Initial z-index set low to maintain overlap structure */
    z-index: 1;
}

.portfolio-card {
    height: 350px; /* Increased height */
    background-size: cover;
    background-position: center;
    /* Initial z-index set low to maintain overlap structure */
}
  
/* Adjust the positions based on screen width */
.portfolio-item:nth-child(1) {
    top: 0;
    left: 0; /* Always at the start */
}
  
.portfolio-item:nth-child(2) {
    top: 150px;
    left: 25%; /* Halfway across the screen */
    transform: translateX(-50%); /* Adjust the position to center at the 50% mark */
}
  
.portfolio-item:nth-child(3) {
    top: 300px;
    left: 50%;
    transform: translateX(-100%); /* Adjust so the right edge aligns with the right screen edge */
}
  
/* Responsive adjustments */
@media (max-width: 768px) {
    .portfolio-item:nth-child(2) {
      left: 10%;
      top: 50px;
    }
    .portfolio-item:nth-child(3) {
      left: 20%;
      top: 100px;
      transform: translateX(0);
    }
    .portfolio-item {
        width: 80%;
    }
    .portfolio-section {
        padding-bottom: 1500px;
    }
}
  
.portfolio-item:hover {
    transform: scale(1.1); /* Scale up */
    z-index: 10; /* Bring to front */
}
  
/* Ensures smooth transition back to original size and position */
.portfolio-item:not(:hover) {
    transform: scale(1);
    z-index: 1; /* Reset z-index */
}
  
.portfolio-item-link {
    text-decoration: none; /* Remove default anchor styling */
    color: inherit; /* Inherit text color */
}
  
.portfolio-item-link:hover .portfolio-item {
    transform: scale(1.1);
    z-index: 10;
}
  