.masonry-grid {
    column-count: 3;
    column-gap: 15px;
  }
  
  .masonry-grid .card {
    break-inside: avoid;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .masonry-grid {
      column-count: 2;
    }
  }
  
  @media (max-width: 576px) {
    .masonry-grid {
      column-count: 1;
    }
  }

.casestudies-bg {
    background-image: linear-gradient(to right, #000821, rgba(0, 8, 33, 0) 50%, rgba(0, 8, 33, 0) 50%, #000821), url('../images/testlab.webp');
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    background-color: #000821; /* This will be the base color */
    padding-top: 50px;
}
  